const navigation = (slider) => {
    const steps = slider.container.dataset.steps ? JSON.parse(slider.container.dataset.steps) : { 0: 1 };

    let wrapper;
    let arrowLeft;
    let arrowRight;

    const hasSlides = () => slider.track.details?.maxIdx > 0;

    const removeElement = (element) => {
        element.parentNode.removeChild(element);
    };

    const createDiv = (className) => {
        const div = document.createElement('div');
        const classNames = className.split(' ');

        classNames.forEach((name) => div.classList.add(name));

        return div;
    };

    const getStepsForMediaQuery = () => {
        const keys = Object.keys(steps);
        keys.sort((a, b) => b - a);

        for (const key of keys) {
            const mediaQuery = window.matchMedia('(min-width: ' + key + 'px)');

            if (mediaQuery.matches) {
                return steps[key];
            }
        }
    };

    const prev = () => {
        const minIdx = slider.track.details.minIdx;
        const prevSlideIdx = slider.track.details.rel - getStepsForMediaQuery();
        const idx = prevSlideIdx < minIdx ? minIdx : prevSlideIdx;

        slider.moveToIdx(idx);
    };

    const next = () => {
        const maxIdx = slider.track.details.maxIdx;
        const nextSlideIdx = slider.track.details.rel + getStepsForMediaQuery();
        const idx = nextSlideIdx > maxIdx ? maxIdx : nextSlideIdx;

        slider.moveToIdx(idx);
    };

    const arrowMarkup = (remove) => {
        if (remove) {
            removeElement(arrowLeft);
            removeElement(arrowRight);
            return;
        }

        arrowLeft = createDiv('arrow arrow-left d-none');
        arrowLeft.addEventListener('click', () => prev());
        arrowRight = createDiv('arrow arrow-right d-none');
        arrowRight.addEventListener('click', () => next());

        wrapper.appendChild(arrowLeft);
        wrapper.appendChild(arrowRight);
    };

    const wrapperMarkup = (remove) => {
        if (remove) {
            const parent = wrapper.parentNode;

            while (wrapper.firstChild) {
                parent.insertBefore(wrapper.firstChild, wrapper);
            }

            removeElement(wrapper);
            return;
        }

        wrapper = createDiv('navigation-wrapper');
        slider.container.parentNode.appendChild(wrapper);
        wrapper.appendChild(slider.container);
    };

    const updateClasses = () => {
        const slide = slider.track.details?.rel;
        const lastSlide = slider.track.details?.maxIdx;

        slide === 0 ? arrowLeft.classList.add('arrow-disabled') : arrowLeft.classList.remove('arrow-disabled');

        slide === lastSlide ? arrowRight.classList.add('arrow-disabled') : arrowRight.classList.remove('arrow-disabled');
    };

    const arrowsVisibility = (show) => {
        if (show) {
            arrowLeft.classList.remove('visible');
            arrowRight.classList.remove('visible');
        } else {
            arrowLeft.classList.add('invisible');
            arrowRight.classList.add('invisible');
        }
    };

    const markup = (remove) => {
        wrapperMarkup(remove);
        arrowMarkup(remove);
        arrowsVisibility(hasSlides());
    };

    slider.on('created', () => {
        markup();
        updateClasses();
    });

    slider.on('optionsChanged', () => {
        markup(true);
        markup();
        updateClasses();
    });

    slider.on('slideChanged', () => {
        updateClasses();
    });

    slider.on('destroyed', () => {
        markup(true);
    });
};

export default navigation;
