import { GTM_ERROR_CODES } from '../error-codes';

(() => {
    function getItemJsonByVariantCode(variantCode) {
        const itemDatasetId = `gtm-item-${variantCode}`;
        const itemDataset = document.getElementById(itemDatasetId);

        return JSON.parse(itemDataset.dataset.gtmJson);
    }

    function getAddToCartJson() {
        const addToCartDatasetId = 'gtm-add-to-cart';
        const addToCartDataset = document.getElementById(addToCartDatasetId);

        return JSON.parse(addToCartDataset.dataset.gtmJson);
    }

    function getRemoveFromCartJson() {
        const removeFromCartDatasetId = 'gtm-remove-from-cart';
        const removeFromCartDataset = document.getElementById(removeFromCartDatasetId);

        return JSON.parse(removeFromCartDataset.dataset.gtmJson);
    }

    document.addEventListener('Cart_OrderItem_QuantityChanged', (e) => {
        const itemJson = getItemJsonByVariantCode(e.detail.variantCode);

        const { newQuantity } = e.detail;
        const { previewsQuantity } = e.detail;

        if (newQuantity > previewsQuantity) {
            const addToCartJson = getAddToCartJson();

            itemJson.quantity = newQuantity - previewsQuantity;
            addToCartJson.ecommerce.items.push(itemJson);

            window.dataLayerPushDeferred({ ecommerce: null });
            window.dataLayerPushDeferred(addToCartJson);
        }

        if (newQuantity < previewsQuantity) {
            const removeFromCartJson = getRemoveFromCartJson();

            itemJson.quantity = previewsQuantity - newQuantity;
            removeFromCartJson.ecommerce.items.push(itemJson);

            window.dataLayerPushDeferred({ ecommerce: null });
            window.dataLayerPushDeferred(removeFromCartJson);
        }
    });

    document.addEventListener('Cart_OrderItem_Removed', (e) => {
        const itemJson = getItemJsonByVariantCode(e.detail.variantCode);
        const removeFromCartJson = getRemoveFromCartJson();

        removeFromCartJson.ecommerce.items.push(itemJson);

        window.dataLayerPushDeferred({ ecommerce: null });
        window.dataLayerPushDeferred(removeFromCartJson);
    });

    document.addEventListener('Cart_Cleared', () => {
        const removeFromCartJson = getRemoveFromCartJson();

        document.querySelectorAll('[id^="gtm-item-"]').forEach((dataset) => {
            removeFromCartJson.ecommerce.items.push(JSON.parse(dataset.dataset.gtmJson));
        });

        window.dataLayerPushDeferred({ ecommerce: null });
        window.dataLayerPushDeferred(removeFromCartJson);
    });

    document.addEventListener('Cart_NoUnitsAvailableTooltip_Click', () => {
        window.dataLayerPushDeferred({
            event: 'error',
            error_id: GTM_ERROR_CODES.WARNING_CART_ITEM_MAX_STOCK_REACHED,
        });
    });

    document.addEventListener('Cart_CrossSell_AddToCart_Click', (e) => {
        const addToCartDatasetId = 'gtm-add-to-cart';
        const addToCartDataset = document.getElementById(addToCartDatasetId);
        const addToCart = JSON.parse(addToCartDataset.dataset.gtmJson);

        const itemDatasetId = `gtm-item-${e.detail.variantCode}`;
        const itemDataset = document.getElementById(itemDatasetId);
        const item = JSON.parse(itemDataset.dataset.gtmJson);

        item.quantity = e.detail.quantity;
        addToCart.ecommerce.items.push(item);

        window.dataLayerPushDeferred({ ecommerce: null });
        window.dataLayerPushDeferred(addToCart);
    });
})();
