import { Repository } from '../utils/Repository';

const repository = new Repository();

document.addEventListener('DOMContentLoaded', () => {
    //---------------------------------------------------------------------------
    // Crea flag de la comunicación en la que se ha hecho clic
    //---------------------------------------------------------------------------

    document.querySelectorAll('.communication').forEach((communicationBlock) => {
        communicationBlock.addEventListener('click', (block) => {
            repository.setPromotionFlag({
                code: block.currentTarget.dataset.communicationCode,
                referer: window.location.href,
            });
        });
    });

    //---------------------------------------------------------------------------
    // Borrar flag de la comunicación en la que se ha hecho clic
    //---------------------------------------------------------------------------

    window.addEventListener('beforeunload', () => {
        const promotionFlag = repository.getPromotionFlag();

        if (promotionFlag && promotionFlag.referer !== window.location.href) {
            repository.removePromotionFlag();
        }
    });
});
