window.addEventListener('load', () => {
    const dfLayerOptions = {
        installationId: process.env.DOOFINDER_INSTALLATION_ID,
        zone: 'eu1',
    };

    if (process.env.NODE_ENV !== 'prod') {
        const UpdateCardLinkHook = {
            mounted() {
                this.changeResults = () => {
                    document.querySelectorAll('.dfd-card-link').forEach(function (cardLink) {
                        const url = new URL(cardLink.href);
                        url.protocol = window.location.protocol;
                        url.hostname = window.location.hostname;
                        url.port = window.location.port;

                        cardLink.href = url.href;
                    });
                };
            },
            updated() {
                this.changeResults();
            },
        };

        dfLayerOptions.hooks = {
            Carousel: UpdateCardLinkHook,
            Results: UpdateCardLinkHook,
            Layer: UpdateCardLinkHook,
        };
    }

    (function (l, a, y, e, r, s) {
        r = l.createElement(a);
        r.onload = e;
        r.defer = 1;
        r.src = y;
        s = l.getElementsByTagName(a)[0];
        s.parentNode.insertBefore(r, s);
    })(document, 'script', 'https://cdn.doofinder.com/livelayer/1/js/loader.min.js', function () {
        doofinderLoader.load(dfLayerOptions);
    });
});
