import { GTM_ERROR_CODES } from '../error-codes';

(() => {
    document.addEventListener('Newsletter_Subscribe', () => {
        window.dataLayerPushDeferred({
            event: 'generate_lead',
            event_type: 'newsletter',
        });
    });

    document.addEventListener('Newsletter_Subscribe_Error', () => {
        window.dataLayerPushDeferred({
            event: 'error',
            error_id: GTM_ERROR_CODES.ERROR_FORM_NEWSLETTER_SUBSCRIBE,
        });
    });
})();
