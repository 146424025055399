class VideoResponsive {
    constructor(video) {
        this.options = {
            video,
            breakpoints: {
                default: {
                    src: video.getAttribute('data-src'),
                    poster: video.getAttribute('data-poster'),
                },
            },
        };

        // get a list of video switching points and links to the videos themselves
        video.querySelectorAll('[data-src]').forEach(
            (element) =>
                (this.options.breakpoints[element.getAttribute('data-mw')] = {
                    src: element.getAttribute('data-src'),
                    poster: element.getAttribute('data-poster'),
                })
        );
        video.innerHTML = ''; // we clean up so that there is nothing superfluous

        // run the handler and track the change in screen width
        this.responseVideo(this.options);
        this.resizer();
    }

    /** Function runs on resize  */
    resizer() {
        window.addEventListener('resize', () => this.responseVideo(this.options));
    }

    /**
     * Change src value of video link to fit screen width
     *
     * @param {Object} options object with options
     */
    responseVideo(options) {
        const { video, breakpoints } = options; // get options
        const widthNow = video.getAttribute('data-width-now') || null;
        const maxBreakpoint = Math.min.apply(
            Math,
            Object.keys(breakpoints)
                .filter((key) => document.body.clientWidth <= key)
                .map(Number)
        );
        const nowBreakpoint = isFinite(maxBreakpoint) ? maxBreakpoint : 'default'; // choose either the maximum value, if not, then the default

        if (widthNow && widthNow == nowBreakpoint) return; // check if the video needs to be changed

        video.setAttribute('data-width-now', nowBreakpoint);

        if (breakpoints[nowBreakpoint].poster) {
            video.setAttribute('poster', breakpoints[nowBreakpoint].poster);
        }

        video.src = breakpoints[nowBreakpoint].src;
    }
}

document.querySelectorAll('.video-responsive').forEach((video) => {
    new VideoResponsive(video);
});
