const placeholder = (slider) => {
    slider.on('created', () => {
        const placeholder = slider.container.querySelector('.slider-placeholder');

        if (placeholder) {
            placeholder.classList.add('d-none');

            slider.container.querySelectorAll('.keen-slider__slide').forEach((slide) => {
                slide.classList.remove('invisible');
            });
        }
    });
};

export default placeholder;
