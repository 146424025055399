import { Repository } from '../utils/Repository';

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.item__link').forEach((card) => {
        card.addEventListener('click', () => {
            const datasetId = document.getElementById(`gtm-select-item-${card.dataset.variantCode}`);
            const selectItemEvent = JSON.parse(datasetId.dataset.gtmJson);

            new Repository().addToItemsSelected(selectItemEvent.ecommerce.items[0]);

            window.dataLayerPushDeferred({ ecommerce: null });
            window.dataLayerPushDeferred(selectItemEvent);
        });
    });
});
