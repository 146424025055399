import { Normalizer } from '../utils/Normalizer';
import { GTM_ERROR_CODES } from '../error-codes';

(() => {
    document.addEventListener('Checkout_Shipping_Complete', (e) => {
        const dataset = document.getElementById('gtm-add-shipping-info');
        const json = JSON.parse(dataset.dataset.gtmJson);

        json.ecommerce.shipping_tier = Normalizer.normalize(e.detail.shippingMethodCode);

        window.dataLayerPushDeferred({ ecommerce: null });
        window.dataLayerPushDeferred(json);
    });

    document.addEventListener('Checkout_GiftWrapperMessage_Error', () => {
        window.dataLayerPushDeferred({
            event: 'error',
            error_id: GTM_ERROR_CODES.ERROR_FORM_CHECKOUT_GIFT_WRAPPER_MESSAGE,
        });
    });

    document.addEventListener('Checkout_ShippingNotes_Error', () => {
        window.dataLayerPushDeferred({
            event: 'error',
            error_id: GTM_ERROR_CODES.ERROR_FORM_CHECKOUT_SHIPPING_NOTES,
        });
    });
})();
