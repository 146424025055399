window.addEventListener('load', () => {
    $.fn.extend({
        joinNewsletter() {
            const form = $(this);
            form.submit((event) => {
                event.preventDefault();

                const legalWarningCheck = form.find('#newsletter-legal-warning');
                if (!legalWarningCheck.is(':checked')) {
                    document.dispatchEvent(new CustomEvent('Newsletter_Subscribe_Error'));

                    form.find('.validation-error').removeClass('d-none');
                    return;
                }

                const successElement = form.find('.msn-success .content');
                const validationElement = form.find('.msn-error .content');

                form.removeClass('error');
                form.find('.validation-error').addClass('d-none');
                form.addClass('loading');

                successElement.text('');
                validationElement.text('');

                $.ajax({
                    url: $(form).attr('action'),
                    type: $(form).attr('method'),
                    data: form.serialize(),
                })
                    .done((response) => {
                        if (response.hasOwnProperty('message')) {
                            successElement.html(response.message);
                            form.removeClass('loading');
                            form.addClass('done');
                        }

                        document.dispatchEvent(new CustomEvent('Newsletter_Subscribe'));
                    })
                    .fail((response) => {
                        if (response.responseJSON.hasOwnProperty('errors')) {
                            form.removeClass('loading');
                            form.addClass('error');

                            const errors = $.parseJSON(response.responseJSON.errors);
                            let message = '';

                            $(errors).each((key, value) => {
                                message += `${value} `;
                            });

                            validationElement.text(message);
                        }

                        document.dispatchEvent(new CustomEvent('Newsletter_Subscribe_Error'));
                    });
            });
        },
    });

    $('#footer-newsletter-form').joinNewsletter();
});
