window.addEventListener('load', () => {
    const toggleEyeIcon = (icon) => {
        if (icon.classList.contains('icon-eye-off')) {
            icon.classList.remove('icon-eye-off');
            icon.classList.add('icon-eye');
        } else {
            icon.classList.remove('icon-eye');
            icon.classList.add('icon-eye-off');
        }
    };

    const toggleInputType = (input) => {
        const type = input.getAttribute('type') === 'password' ? 'text' : 'password';
        input.setAttribute('type', type);
    };

    document.querySelectorAll('.toggle-password').forEach((element) => {
        const passwordInput = element.previousElementSibling;

        if (passwordInput) {
            element.addEventListener('click', () => {
                toggleInputType(passwordInput);
                toggleEyeIcon(element);
            });
        }
    });
});
