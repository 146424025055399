(() => {
    const productShowVariantPostLoadEvent = 'ProductShow_Variant_PostLoad';

    const sendConnectifEvent = (variant) => {
        if (variant) {
            const productVisitedEventDatasetId = `cn-product-visited-${variant.code}`;
            const productVisitedEventDataset = document.getElementById(productVisitedEventDatasetId);
            const productVisited = JSON.parse(productVisitedEventDataset.dataset.cnJson);

            connectif.managed.sendEvents([productVisited]);
        }
    };

    const cnInitialized = new Promise((resolve) => {
        document.addEventListener('connectif.managed.initialized', resolve);
    });

    const variantLoad = new Promise((resolve) => {
        document.addEventListener(productShowVariantPostLoadEvent, resolve);
    });

    const getVariantFromEvents = (events) => {
        let variant = null;

        events.forEach((event) => {
            if (event.type === productShowVariantPostLoadEvent) {
                variant = event.detail.variant;
            }
        });

        return variant;
    };

    let eventsInitialized = false;

    Promise.all([cnInitialized, variantLoad]).then((events) => {
        eventsInitialized = true;
        sendConnectifEvent(getVariantFromEvents(events));
    });

    document.addEventListener(productShowVariantPostLoadEvent, (event) => {
        if (eventsInitialized) {
            sendConnectifEvent(event.detail.variant);
        }
    });
})();
