export class Normalizer {
    static normalize(value) {
        return (
            value
                // Reemplaza espacios y caracteres de subrayado con un guion
                .replace(/(\s|_)/g, '-')
                // Elimina caracteres no alfanuméricos
                .replace(/[^a-zA-Z0-9-]/g, '')
                // Convierte a minúsculas
                .toLowerCase()
        );
    }
}
