(function () {
    window.addEventListener('load', function () {
        const now = new Date();
        const currentWeekDay = now.getDay();
        const currentHour = now.getHours();

        if (currentWeekDay >= 1 && currentWeekDay <= 5 && currentHour >= 10 && currentHour <= 19) {
            const script = document.createElement('script');
            script.src = '//code.tidio.co/wik1wqruajjutujyrwbn1ftugdmeob7l.js';
            script.defer = true;
            document.body.appendChild(script);
        }
    });
})();
