import { GTM_ERROR_CODES } from '../error-codes';
import { Repository } from '../utils/Repository';

(() => {
    document.addEventListener('ProductShow_Variant_PostLoad', (e) => {
        const { variant } = e.detail;

        //---------------------------------------------------------------------------
        // "view_item" en la carga de una variante
        //---------------------------------------------------------------------------

        const viewItemEventDatasetId = `gtm-view-item-${variant.code}`;
        const viewItemEventDataset = document.getElementById(viewItemEventDatasetId);
        const viewItemEvent = JSON.parse(viewItemEventDataset.dataset.gtmJson);

        new Repository().addToItemsSelected(viewItemEvent.ecommerce.items[0]);

        window.dataLayerPushDeferred({ ecommerce: null });
        window.dataLayerPushDeferred(viewItemEvent);

        //---------------------------------------------------------------------------
        // "view_item_list" de venta cruzada en la carga de una variante
        //---------------------------------------------------------------------------

        const viewItemListCrossSellingDatasetId = `gtm-view-item-list-cross-selling-${variant.code}`;
        const viewItemListCrossSellingDataset = document.getElementById(viewItemListCrossSellingDatasetId);

        if (viewItemListCrossSellingDataset) {
            window.dataLayerPushDeferred({ ecommerce: null });
            window.dataLayerPushDeferred(JSON.parse(viewItemListCrossSellingDataset.dataset.gtmJson));
        }

        //---------------------------------------------------------------------------
        // "error" al cargar variante sin stock
        //---------------------------------------------------------------------------

        if (!variant.isSellable) {
            window.dataLayerPushDeferred({
                event: 'error',
                error_id: GTM_ERROR_CODES.INFO_VARIANT_NO_STOCK,
            });
        }
    });

    //---------------------------------------------------------------------------
    // "add_to_cart" en click de botón
    //---------------------------------------------------------------------------

    document.addEventListener('ProductShow_AddToCart_Click', (e) => {
        const addToCartDatasetId = 'gtm-add-to-cart';
        const addToCartDataset = document.getElementById(addToCartDatasetId);
        const addToCart = JSON.parse(addToCartDataset.dataset.gtmJson);

        const itemDatasetId = `gtm-item-${e.detail.variant.code}`;
        const itemDataset = document.getElementById(itemDatasetId);
        const item = JSON.parse(itemDataset.dataset.gtmJson);

        const quantity = parseInt(e.detail.quantity);

        item.quantity = quantity;
        addToCart.ecommerce.items.push(item);

        if (e.detail.crossSellingVariant) {
            const crossSellingItemDatasetId = `gtm-item-${e.detail.crossSellingVariant.code}`;
            const crossSellingItemDataset = document.getElementById(crossSellingItemDatasetId);
            const crossSellingItemStock = parseInt(crossSellingItemDataset.dataset.stock);
            const crossSellingItem = JSON.parse(crossSellingItemDataset.dataset.gtmJson);

            crossSellingItem.quantity = quantity > crossSellingItemStock ? crossSellingItemStock : quantity;
            addToCart.ecommerce.items.push(crossSellingItem);

            new Repository().addToItemsSelected(crossSellingItem);
        }

        window.dataLayerPushDeferred({ ecommerce: null });
        window.dataLayerPushDeferred(addToCart);
    });

    //---------------------------------------------------------------------------
    // "stock_notification" submit form
    //---------------------------------------------------------------------------

    document.addEventListener('ProductShow_StockNotificationForm_Submit', (e) => {
        window.dataLayerPushDeferred({
            event: 'generate_lead',
            event_type: 'alta_stock',
            currency: 'EUR',
            value: e.detail.price,
        });
    });

    //---------------------------------------------------------------------------
    // "error" al aumentar cantidad de variante
    //---------------------------------------------------------------------------

    document.addEventListener('ProductShow_IncreaseQuantity_Click', (e) => {
        const { variant } = e.detail;
        const { quantity } = e.detail;

        if (!variant.isSellable || (variant.isTracked && quantity >= variant.stock)) {
            window.dataLayerPushDeferred({
                event: 'error',
                error_id: GTM_ERROR_CODES.WARNING_VARIANT_MAX_STOCK_REACHED,
            });
        }
    });

    //---------------------------------------------------------------------------
    // "error" al agregar al carrito una variante sin stock
    //---------------------------------------------------------------------------

    document.addEventListener('ProductShow_AddToCart_Error', () => {
        window.dataLayerPushDeferred({
            event: 'error',
            error_id: GTM_ERROR_CODES.ERROR_FORM_NOT_ENOUGH_STOCK,
        });
    });
})();
