(function () {
    const scheduledDeliveryPostCodeChecker = document.querySelectorAll('.scheduled-delivery-postcode-checker');

    const formatResponseData = (data) => {
        let formattedData = '';

        data.forEach((element) => {
            formattedData += `${element}<br/>`;
        });

        return formattedData;
    };

    const getScheduledDates = async (url, data) => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: JSON.stringify(data),
        });

        return response.json();
    };

    scheduledDeliveryPostCodeChecker.forEach((element) => {
        const inputField = element.querySelector('input');
        const submitButton = element.querySelector('.submit-button');
        const formElement = element.querySelector('.scheduled-delivery-form');
        const responseElement = element.querySelector('.scheduled-delivery-response');
        const successResponseElement = element.querySelector('.scheduled-delivery-response-success');
        const successResponseData = element.querySelector('.scheduled-delivery-response-data');
        const failResponseElement = element.querySelector('.scheduled-delivery-response-fail');
        const requiredFieldMEssageElement = element.querySelector('.scheduled-delivery-required-field');
        const postcodeElement = element.querySelector('.scheduled-delivery-postcode');
        const changePostcodeElement = element.querySelector('.scheduled-delivery-change-postcode');

        submitButton.addEventListener('click', () => {
            const postcode = inputField.value;

            requiredFieldMEssageElement.classList.add('d-none');

            if (!postcode) {
                requiredFieldMEssageElement.classList.remove('d-none');
                return;
            }

            getScheduledDates(element.dataset.url, { postcode: postcode }).then((data) => {
                formElement.classList.add('d-none');
                responseElement.classList.remove('d-none');
                postcodeElement.innerText = postcode;

                if (data.status === 'fail') {
                    successResponseData.innerHTML = '';
                    successResponseElement.classList.add('d-none');
                    failResponseElement.classList.remove('d-none');
                } else {
                    failResponseElement.classList.add('d-none');
                    successResponseData.innerHTML = formatResponseData(data.data);
                    successResponseElement.classList.remove('d-none');
                }
            });
        });

        changePostcodeElement.addEventListener('click', () => {
            formElement.classList.remove('d-none');
            responseElement.classList.add('d-none');
            failResponseElement.classList.add('d-none');
            successResponseElement.classList.add('d-none');
        });

        document.addEventListener('keypress', (event) => {
            if (document.activeElement === inputField && event.key === 'Enter') {
                event.preventDefault();
                submitButton.dispatchEvent(new Event('click'));
            }
        });
    });
})();
