import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

// TODO: optimizar para mejorar performance
Sentry.init({
    dsn: 'https://76546d5493dd40b989ce7abc1f06eac0@o481151.ingest.sentry.io/6701748',
    debug: false,
    release: process.env.APP_VERSION,
    environment: process.env.APP_ENV,
    tracesSampleRate: 0.1,
    // This sets the sample rate to be 0%. You may want this to be 100% while
    // in development and sample at a lower rate in production.
    // replaysSessionSampleRate: 0.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // replaysOnErrorSampleRate: 1.0,
    integrations: [
        new Sentry.BrowserTracing(),
        // new Sentry.Replay(),
        new CaptureConsole({
            levels: ['error'],
        }),
    ],
});
