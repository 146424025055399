import AppStorage from '../../utils/storage';

import {
    GTM_ITEMS_CLICKED_STORAGE_KEY,
    GTM_ITEM_CLICKED_EXPIRATION,
    GTM_PROMOTION_ASSOCIATED_ITEMS_STORAGE_KEY,
    GTM_PROMOTION_ASSOCIATED_ITEM_EXPIRATION,
    GTM_PROMOTION_CLICKED_FLAG_STORAGE_KEY,
} from '../constants';

export class Repository {
    //---------------------------------------------------------------------------
    // Getters
    //---------------------------------------------------------------------------

    getItemsClicked() {
        const data = AppStorage.getItem(GTM_ITEMS_CLICKED_STORAGE_KEY);

        return data ? JSON.parse(data) : {};
    }

    getPromotionsItemsAssociation() {
        const data = AppStorage.getItem(GTM_PROMOTION_ASSOCIATED_ITEMS_STORAGE_KEY);

        return data ? JSON.parse(data) : {};
    }

    getPromotionFlag() {
        const data = AppStorage.getItem(GTM_PROMOTION_CLICKED_FLAG_STORAGE_KEY);

        return data ? JSON.parse(data) : null;
    }

    //---------------------------------------------------------------------------
    // Setters
    //---------------------------------------------------------------------------

    setPromotionFlag(promotion) {
        AppStorage.setItem(GTM_PROMOTION_CLICKED_FLAG_STORAGE_KEY, JSON.stringify(promotion));
    }

    //---------------------------------------------------------------------------
    // Adders
    //---------------------------------------------------------------------------

    addToItemsSelected(newItem) {
        console.assert(newItem.hasOwnProperty('item_list_name'), newItem);
        console.assert(newItem.hasOwnProperty('index'), newItem);

        const items = this.getItemsClicked();

        let create = true;

        if (items[newItem.item_name] !== undefined) {
            const createdSinceInSeconds =
                (new Date().getTime() - new Date(items[newItem.item_name].createdAt).getTime()) / 1000;

            if (GTM_ITEM_CLICKED_EXPIRATION > createdSinceInSeconds) {
                create = false;
            }
        }

        if (create) {
            items[newItem.item_name] = {
                itemListName: newItem.item_list_name,
                index: newItem.index,
                createdAt: new Date(),
            };

            AppStorage.setItem(GTM_ITEMS_CLICKED_STORAGE_KEY, JSON.stringify(items));
        }
    }

    addItemsToPromotion(items, promotionCode) {
        console.assert(typeof promotionCode === 'string');

        const promotionAssociatedItems = this.getPromotionsItemsAssociation();

        items.forEach((item) => {
            let needToCreate = true;

            if (promotionAssociatedItems.hasOwnProperty(item.item_name)) {
                const createdSinceInSeconds =
                    (new Date().getTime() - new Date(promotionAssociatedItems[item.item_name].createdAt).getTime()) /
                    1000;

                if (createdSinceInSeconds < GTM_PROMOTION_ASSOCIATED_ITEM_EXPIRATION) {
                    needToCreate = false;
                }
            }

            if (needToCreate) {
                promotionAssociatedItems[item.item_name] = {
                    promotionName: promotionCode,
                    createdAt: new Date(),
                };
            }
        });

        AppStorage.setItem(GTM_PROMOTION_ASSOCIATED_ITEMS_STORAGE_KEY, JSON.stringify(promotionAssociatedItems));
    }

    //---------------------------------------------------------------------------
    // Removers
    //---------------------------------------------------------------------------

    removePromotionFlag() {
        AppStorage.removeItem(GTM_PROMOTION_CLICKED_FLAG_STORAGE_KEY);
    }
}
