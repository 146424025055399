const DIRECTION_UP = -1;
const DIRECTION_DOWN = 1;

const HEADER_HEIGHT = 300;
const SHOW_HEADER_THRESHOLD = 150;
const HIDE_HEADER_THRESHOLD = 50;

const popupOverlay = document.getElementById('popup-overlay');

if ($(window).width() < 992) {
    $('.rm-dropdown').on('click', function (e) {
        if ($(this).closest('.rm-menu').hasClass('rm-mobile')) {
            e.preventDefault();
            e.stopPropagation();

            $(this).next().addClass('rm-view');
            var index = $(this).parent().index();

            $(this)
                .parent()
                .parent()
                .children()
                .each(function () {
                    if (index == $(this).index()) {
                        $(this).removeClass('rm-hidden');
                        $(this).addClass('rm-visible');
                    } else {
                        $(this).removeClass('rm-visible');
                        $(this).addClass('rm-hidden');
                    }
                });
            $(this).addClass('rm-hidden-header');
        }
    });

    $('.nav-back-button').on('click', function () {
        $(this).closest('.rm-view').prev().removeClass('rm-hidden-header');
        $(this).closest('.rm-view').removeClass('rm-view');
        $(this).parent().parent().parent().parent().find('div').removeClass('rm-hidden');
    });

    $('.navbar-toggler').on('click', function () {
        $('html,body').toggleClass('block-scroll');
        $('.header-top.sticky').toggleClass('fixed');
        $('.navbar').toggleClass('active');
    });

    // fix para ocultar menu en navegadores que almacenan scripts en cache al pulsar boton de volver
    window.addEventListener('pageshow', (event) => {
        if (event.persisted) {
            $('html,body').removeClass('block-scroll');
            $('.bg-opacity-modal').removeClass('active');
            $('#main_nav').removeClass('show');
            $('.header-top.sticky').removeClass('fixed');
            $('.navbar').removeClass('active');
        }
    });
} // end width < 992

if ($(window).width() > 992) {
    const hideSubmenus = () => {
        document.querySelectorAll('.has-megamenu').forEach((element) => {
            element.querySelector('.rm-submenu').classList.remove('show');
        });
    };

    const hideMegaMenu = () => {
        hideSubmenus();
        document.body.classList.remove('block-scroll');
        popupOverlay.classList.remove('bg-white-active');
    };

    document.querySelectorAll('.has-megamenu').forEach((element) => {
        element.addEventListener('click', (e) => {
            if (
                element.querySelector('.rm-submenu').classList.contains('show') &&
                !e.target.classList.contains('dropdown-menu') &&
                !e.target.classList.contains('megamenu-container') &&
                !e.target.classList.contains('nav__category-second-level')
            ) {
                element.querySelector('.rm-submenu').classList.remove('show');
                document.body.classList.remove('block-scroll');
                popupOverlay.classList.remove('bg-white-active');
            } else {
                hideSubmenus();
                element.querySelector('.rm-submenu').classList.add('show');
                document.body.classList.add('block-scroll');
                popupOverlay.classList.add('bg-white-active');
            }
        });
    });

    if (popupOverlay) {
        popupOverlay.addEventListener('click', () => {
            hideMegaMenu();
        });
    }

    $(document).on('keyup', (event) => {
        if (event.key === 'Escape' && popupOverlay) {
            const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
            if (isNotCombinedKey) {
                hideMegaMenu();
            }
        }
    });
} // end width > 992

function hideHeaderScrollDown() {
    const header = document.getElementById('header');

    let currentPosition = null;
    let previousPosition = null;
    let currentDirection = null;
    let previousDirection = null;
    let directionChangedPosition = null;

    function hideHeader() {
        if (header !== null) {
            header.classList.add('hide');
        }
    }

    function makeHeaderSticky() {
        if (header !== null) {
            header.classList.remove('hide');
            header.classList.add('sticky');
        }
    }

    const toggleHeaderVisibility = function () {
        currentPosition = window.scrollY || document.documentElement.scrollTop;
        previousPosition = previousPosition ?? currentPosition;

        currentDirection = currentPosition > previousPosition ? DIRECTION_DOWN : DIRECTION_UP;
        previousDirection = previousDirection ?? currentDirection;

        if (currentDirection !== previousDirection) {
            directionChangedPosition = currentPosition;
        }

        if (
            currentDirection === DIRECTION_DOWN &&
            currentPosition > directionChangedPosition + HIDE_HEADER_THRESHOLD &&
            currentPosition > HEADER_HEIGHT
        ) {
            hideHeader();
        }

        if (currentDirection === DIRECTION_UP && currentPosition < directionChangedPosition - SHOW_HEADER_THRESHOLD) {
            makeHeaderSticky();
        }

        previousPosition = currentPosition;
        previousDirection = currentDirection;
    };

    window.addEventListener('scroll', toggleHeaderVisibility);

    document.body.addEventListener(
        'wheel',
        (e) => {
            if (e.currentTarget.classList.contains('block-scroll')) {
                e.preventDefault();
            }
        },
        { passive: false }
    );
}

hideHeaderScrollDown();
