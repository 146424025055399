//-----------------------------------------------------
// Production only JS
//-----------------------------------------------------

if (process.env.APP_ENV === 'prod') {
    require('../js/trackers/sentry');
    // require('../js/trackers/hotjar');
}

// -----------------------------------------------------
// Global vendor
// -----------------------------------------------------

import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/tab';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/selectmenu';

//-----------------------------------------------------
// Global JS
//-----------------------------------------------------

import 'shop/assets/js/analytics/gtm';
import 'shop/assets/js/component/form/newsletter-subscription';
import 'shop/assets/js/component/form/show-password';
import 'shop/assets/js/component/shared/doofinder';
import 'shop/assets/js/component/shared/login-modal';
import 'shop/assets/js/component/shared/menu-responsive-horizontal';
import 'shop/assets/js/component/shared/mobile-accordion';
import 'shop/assets/js/component/shared/scheduled-delivery-post-code-checker';
import 'shop/assets/js/component/shared/slider/slider';
import 'shop/assets/js/component/shared/tidio';
import 'shop/assets/js/component/shared/tooltip';
import 'shop/assets/js/component/shared/trustpilot';
import 'shop/assets/js/component/shared/video-responsive';
import 'shop/assets/js/connectif/index';

//-----------------------------------------------------
// Global CSS
//-----------------------------------------------------

import 'shop/assets/scss/index.scss';
