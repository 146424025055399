import Modal from 'bootstrap/js/dist/modal';

(function () {
    const loginLink = document.getElementById('login-link');
    const loginModal = document.getElementById('login-modal');

    if (loginLink && loginModal) {
        const modal = new Modal(loginModal);

        loginLink.addEventListener('click', (e) => {
            e.preventDefault();
            modal.show();
        });

        loginModal.addEventListener('shown.bs.modal', () => {
            document.getElementById('_username').focus();
        });
    }
})();
