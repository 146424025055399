export default class AppStorage {
    static getItem(key) {
        if (this.storageAvailable()) {
            return window.localStorage?.getItem(key);
        }

        return undefined;
    }

    static setItem(key, value) {
        if (this.storageAvailable()) {
            window.localStorage?.setItem(key, value);
        }
    }

    static removeItem(key) {
        if (this.storageAvailable()) {
            window.localStorage?.removeItem(key);
        }
    }

    /**
     * Feature-detecting localStorage
     * To be able to use localStorage, we should first verify that it is supported and available in the current browsing session.
     * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API?ref=hackernoon.com#feature-detecting_localstorage
     */
    static storageAvailable(type = 'localStorage') {
        let storage;

        try {
            storage = window[type];

            const x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);

            return true;
        } catch (e) {
            return (
                e instanceof DOMException &&
                // everything except Firefox
                (e.code === 22 ||
                    // Firefox
                    e.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    e.name === 'QuotaExceededError' ||
                    // Firefox
                    e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage &&
                storage.length !== 0
            );
        }
    }
}
