import KeenSlider from 'keen-slider';
import lazy from './lazy';
import navigation from './navigation';
import placeholder from './placeholder';

const getProductOptions = () => ({
    slides: {
        perView: 2.3,
        spacing: 16,
    },
    breakpoints: {
        '(min-width: 576px)': {
            slides: {
                perView: 3.3,
                spacing: 16,
            },
        },
        '(min-width: 768px)': {
            slides: {
                perView: 3.4,
                spacing: 16,
            },
        },
        '(min-width: 992px)': {
            slides: {
                perView: 4.4,
                spacing: 16,
            },
        },
        '(min-width: 1200px)': {
            slides: {
                perView: 5,
                spacing: 16,
            },
        },
        '(min-width: 1400px)': {
            slides: {
                perView: 4,
                spacing: 32,
            },
        },
    },
});

const getFeaturedProductOptions = () => ({
    slides: {
        perView: 2.3,
        spacing: 16,
    },
    breakpoints: {
        '(min-width: 576px)': {
            slides: {
                perView: 3.3,
                spacing: 16,
            },
        },
        '(min-width: 768px)': {
            slides: {
                perView: 4.3,
                spacing: 16,
            },
        },
        '(min-width: 992px)': {
            slides: {
                perView: 4,
                spacing: 32,
            },
        },
        '(min-width: 1200px)': {
            slides: {
                perView: 5,
                spacing: 32,
            },
        },
    },
});

const getBlogPostOptions = () => ({
    slides: {
        perView: 1.3,
        spacing: 28,
    },
    breakpoints: {
        '(min-width: 576px)': {
            slides: {
                perView: 1.6,
                spacing: 28,
            },
        },
        '(min-width: 768px)': {
            slides: {
                perView: 2.3,
                spacing: 28,
            },
        },
        '(min-width: 992px)': {
            slides: {
                perView: 3,
                spacing: 28,
            },
        },
        '(min-width: 1200px)': {
            slides: {
                perView: 3.6,
                spacing: 28,
            },
        },
        '(min-width: 1400px)': {
            slides: {
                perView: 4.3,
                spacing: 28,
            },
        },
    },
});

const getLogosOptions = () => ({
    slides: {
        perView: 1.5,
        spacing: 30,
    },
    breakpoints: {
        '(min-width: 576px)': {
            slides: {
                perView: 2,
                spacing: 30,
            },
        },
        '(min-width: 768px)': {
            slides: {
                perView: 3,
                spacing: 30,
            },
        },
        '(min-width: 992px)': {
            slides: {
                perView: 4,
                spacing: 30,
            },
        },
    },
});

const getSocialOptions = () => ({
    slides: {
        perView: 1.1,
    },
    breakpoints: {
        '(min-width: 576px)': {
            slides: {
                perView: 1.5,
            },
        },
        '(min-width: 768px)': {
            slides: {
                perView: 2.2,
            },
        },
        '(min-width: 992px)': {
            slides: {
                perView: 3,
            },
        },
        '(min-width: 1200px)': {
            slides: {
                perView: 3.2,
            },
        },
        '(min-width: 1400px)': {
            slides: {
                perView: 4,
            },
        },
    },
});

const getTaxonComponentOptions = () => ({
    slides: {
        perView: 2.2,
        spacing: 16,
    },
    breakpoints: {
        '(min-width: 576px)': {
            slides: {
                perView: 3.5,
                spacing: 16,
            },
        },
        '(min-width: 768px)': {
            slides: {
                perView: 5,
                spacing: 16,
            },
        },
        '(min-width: 992px)': {
            slides: {
                perView: 4.2,
                spacing: 24,
            },
        },
        '(min-width: 1200px)': {
            slides: {
                perView: 6,
                spacing: 24,
            },
        },
    },
});

const getAutoWidthOptions = () => ({
    slides: {
        perView: 'auto',
        spacing: 24,
    },
});

const getOptions = (type, size, mode) => {
    const options = {
        renderMode: 'performance',
        mode: mode || 'snap',
        dragSpeed: 0.8,
    };

    switch (type) {
        case 'product':
            Object.assign(options, getProductOptions());
            break;
        case 'featured-product':
            Object.assign(options, getFeaturedProductOptions());
            break;
        case 'blog-post':
            Object.assign(options, getBlogPostOptions());
            break;
        case 'logos':
            Object.assign(options, getLogosOptions());
            break;
        case 'social':
            Object.assign(options, getSocialOptions());
            break;
        case 'taxon-component':
            Object.assign(options, getTaxonComponentOptions());
            break;
        case 'auto-width':
            Object.assign(options, getAutoWidthOptions());
            break;
        default:
            break;
    }

    return options;
};

const sliderInit = (element, options) => {
    const slider = new KeenSlider(element, options, [navigation, lazy, placeholder]);
};

document.querySelectorAll('.keen-slider').forEach((element) => {
    const { size } = element.dataset;
    const { type } = element.dataset;
    const { mode } = element.dataset;

    sliderInit(element, getOptions(type, size, mode));
});

window.addEventListener('load', () => {
    document.querySelectorAll('.keen-slider').forEach((slider) => {
        const sliderImage = slider.querySelector('img');
        const viewMoreSlide = slider.querySelector('.view-more-slide');

        if (slider.parentElement.querySelector('.arrow')) {
            slider.parentElement.querySelectorAll('.arrow').forEach((arrow) => {
                if (sliderImage) {
                    const arrowPosition = sliderImage.height / 2;
                    arrow.setAttribute('style', `top: ${arrowPosition}px;`);
                }

                arrow.classList.remove('d-none');
            });
        }

        if (sliderImage && viewMoreSlide) {
            // timeout para asegurar que en móviles viejos haya terminado la carga del carrusel
            setTimeout(() => {
                const imageHeight = sliderImage.height;
                viewMoreSlide.setAttribute('style', `height: ${imageHeight}px !important`);
            }, 1000);
        }
    });
});
