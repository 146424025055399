window.addEventListener('load', () => {
    if (window.matchMedia('screen and (min-width:1200px)').matches) {
        let mobileAccordions = document.querySelectorAll('.mobile-accordion');

        mobileAccordions.forEach((element) => {
            let accordionButton = element.querySelector('.accordion-button');
            let accordionCollapse = element.querySelector('.accordion-collapse');

            delete accordionButton.dataset.bsToggle;
            accordionCollapse.classList.add('show');
        });
    }
});
